.cover {
    background-image: url('/public/images/cover.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.cover__title1,
.cover__title2,
.cover__subtitle {
    color: #E1E1E1;
}

.cover__title1,
.cover__title2 {
    text-shadow: 0px 4.216px 4.216px #000;
    font-family: Galada;
}

.cover__title1 {
    font-size: 20px;

}

.cover__title2 {
    font-size: 54px;

}

.cover__subtitle {
    text-shadow: 3.378px 3.378px 3.378px #000;
    font-family: 'Bebas Neue';
    font-size: 20px;
    padding-bottom: 1.75rem;
}

.page1__title {
    color: #FFF;
    text-shadow: 4.203px 4.203px 4.203px #000;
    font-family: 'Galada';
    font-size: 32px;
    width: fit-content;
    padding: 4rem 0 0 4rem;
    line-height: 1.1;
}


.page3__title {
    color: #FFF;
    position: relative;
    z-index: 1;
    text-align: center;
    text-shadow: 8.966px 8.966px 8.966px #000;
    font-family: 'Galada';
    font-size: 32px;
    width: fit-content;
    line-height: 1;
    margin: auto;
    padding-top: 8rem;
}

.page4__cover {
    background-image: url('/public/images/page4-cover.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.page5__title {
    color: #FFF;
    z-index: 1;
    position: relative;
    text-align: center;
    text-shadow: 8.966px 8.966px 8.966px #000;
    font-family: 'Galada';
    font-size: 32px;
    width: fit-content;
    line-height: 1;
    margin: auto;

}


@screen md {
    .cover__title1 {
        font-size: 32px;
    
    }
    
    .cover__title2 {
        font-size: 64px;
    
    }

    .cover__subtitle {
        font-size: 24px;
    
    }

    .page1__title {
        font-size: 44px;

    }

    .page3__title {
        font-size: 48px;
        padding-top: 54px;
        line-height: 1;

    }

    .page5__title {
        font-size: 48px;
        line-height: 1;

    }
}

.page5__cover {
    background-image: url('/public/images/page5-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}
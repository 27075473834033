@import "transition-style";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .common-transition {
    @apply transition-all duration-200;
  }

  .center-image {
    @apply -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2;
  }
}

body {
  font-family: "Squada One";
  overflow: hidden;
}

.wrapper {
  align-items: center;
  display: flex;
  height: 90%;
  justify-content: center;
  margin: 5%;
  width: 90%;
}

.aspect {
  padding-bottom: 70%;
  position: relative;
  width: 65%;
}

.aspect-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 768px) {
  .wrapper {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    margin: 0px;
  }

  .aspect {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .aspect-inner {
    display: flex;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .flipbook {
    margin: auto !important;
  }
}

.flipbook {
  height: 100%;
  transition: margin-left 0.25s ease-out;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: auto;
}

.flipbook .page {
  height: 100%;
  width: 100%;
}

.turn-page-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  -ms-perspective: 2000px;
  perspective: 2000px;
}

.bg-page {
  background: #870102;
  color: #e1e1e1;
}

.page-common {
  width: 100%;
  height: 100%;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

/* page 14 */
.font-bebas {
  font-family: "Bebas Neue";
}

.font-galada {
  font-family: "Galada";
}

.text-shadow {
  text-shadow: 5px 5px 10px #000;
  line-height: 88%;
}

/* page 13 */
.bg-x-icon {
  background-image: url("./../public/x-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-telegram-icon {
  background-image: url("./../public/telegram-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-page-13 {
  background-image: url("./../public/bg-page-13.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}

/* page 11 */
/* .bg-page-11 {
  background-image: url("./../public/bg-page-11.png");
  background-size: contain;
  background-repeat: round;
} */

/* page 10 */
.bg-page-10 {
  background-image: url("./../public/bullet.png");
  background-repeat: no-repeat;
  background-size: 130px;
  background-position-x: 0px;
  background-position-y: 50px;
}

/* page 9 */
/* .bg-page-9 {
  background-image: url("./../public/bg-page-9.png");
  background-repeat: no-repeat;
  background-size: cover;
} */
.slide-bottom {
  animation-name: slide-bottom;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  transform: translateY(0) translateX(0);
}

.slide-tl {
  transform: translateY(0) translateX(0);
  animation: slide-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.slide-top {
  animation-name: slide-top;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}
@keyframes rotate-scale-up {
  0% {
    transform: scale(1) rotateZ(0);
  }

  50% {
    transform: scale(1.1) rotateZ(180deg);
  }

  100% {
    transform: scale(1) rotateZ(360deg);
  }
}

@keyframes shadow-drop-2-center {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

@keyframes wipe-out-up {
  from {
    clip-path: inset(0 0 0 0);
  }

  to {
    clip-path: inset(0 0 100% 0);
  }
}

@keyframes shadow-drop-2-center-dot-right {
  0% {
    opacity: 0.8;
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1.2);
    /* right: 15px; */
    box-shadow: 0 0 20px 0px rgba(155, 231, 93, 0.35);
  }
}

@keyframes shadow-drop-2-center-dot-left {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
    left: 15px;
  }
}
/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
.slide-right {
  -webkit-animation: slide-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite;
  animation: slide-right 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/**
 * ----------------------------------------
 * animation slide-tl
 * ----------------------------------------
 */

@keyframes slide-tl {
  0% {
    -webkit-transform: translateY(-100px) translateX(-100px);
    transform: translateY(-100px) translateX(-100px);
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* [transition-style="out:wipe:up"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-out-up both;
} */

#root {
  position: relative;
  z-index: 10;
}

.scale-2 {
  transform: scale(1.5);
}

.page-common:hover .enabled {
  display: flex !important;
}

.dot-doc-right {
  position: absolute;
  z-index: 15;
  bottom: 15px;
  right: 15px;
  animation-name: shadow-drop-2-center-dot-right;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.dot-doc-left {
  display: none;
  position: absolute;
  z-index: 15;
  bottom: 15px;
  left: 15px;
  animation-name: shadow-drop-2-center-dot-right;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.rotate-center {
  animation: rotate-center infinite 0.5s ease-in-out both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-vert-center {
  animation: rotate-vert-center infinite 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes rotate-vert-center {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.flip-horizontal-top {
  animation: flip-horizontal-top infinite 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes flip-horizontal-top {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.vibrate-2 {
	animation: vibrate-2 0.3s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-3 9:9:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-2
 * ----------------------------------------
 */
 @keyframes vibrate-2 {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(1.75px, -1.75px);
  }
  40% {
    transform: translate(1.75px, 1.75px);
  }
  60% {
    transform: translate(-1.75px, 1.75px);
  }
  80% {
    transform: translate(-1.75px, -1.75px);
  }
  100% {
    transform: translate(0);
  }
}

.flicker-2 {
	animation: flicker-2 2s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-3 9:10:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-2
 * ----------------------------------------
 */
 @keyframes flicker-2 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  45.99% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  46.9% {
    opacity: 0;
  }
  46.91% {
    opacity: 1;
  }
  51.99% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  52.8% {
    opacity: 0;
  }
  52.81% {
    opacity: 1;
  }
}

.jello-horizontal {
	animation: jello-horizontal 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-3 9:12:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
